import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"

class Implementation extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Zoom Installation and Removing"}
          titleDesc={"Zoom Installation and Removing"}
          titleKeywords={"Zoom Installation and Removing"}
          fbDes={"Zoom Installation and Removing"}
          twitDesc={"Zoom Installation and Removing"}
          seoLink={"install_zoom/"}
        />

        {/* top section  */}
        <section role="top--section">
          <article className="container h-100">
            <Row className="align-items-center justify-content-center pb-5 h-100">
              <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 2 }}>
                <img src="/images/zoom/zoomlaptop.webp" alt="Connect zoom width us" className="img-fluid mt-4" />
              </Col>
              <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }}>
                <div className="my-5">
                  <h1 className="font-weight-bold">Install Zoom with us</h1>
                  <div className="h4 mt-4 font-weight-semibold">
                    Enhance your remote learning experience with instant access to Zoom video meetings.
                  </div>

                  {/* <div className="mt-4">
                    <Link
                      to="#"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Connect Zoom")}
                      state={{ page: "install_zoom" }}
                    >
                      Connect Zoom
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Steps to connect Zoom with Fuseclassroom */}
        <section className="py-5 bg-80 right" id="adoption">
          <article className="container">
            <Row className="">
              <Col md="12">
                <h2 className="font-weight-bold">Connecting Zoom Accounts with Fuse Classroom</h2>
              </Col>
              <Col lg={{ span: 7, order: 2 }} className="my-5 text-right">
                <img
                  src="/images/zoom/connect-to-zoom.webp"
                  alt="Steps to connect Zoom with Fuseclassroom"
                  className="img-fluid connect-to-zoom"
                />
              </Col>

              <Col lg={{ span: 5, order: 1 }} className="my-lg-5">
                <div className="py-4 large-text">
                  Fuse Classroom Admin can easily integrate their Zoom accounts with Fuse Classroom.
                </div>

                <ul className="h5">
                  <li className="mb-3">1. Login to Fuse Classroom School Admin platform.</li>
                  <li className="mb-3">2. Click on Settings and go to Live Class Settings.</li>
                  <li className="mb-3">3. Click on Integrate Zoom Account option.</li>
                </ul>
              </Col>

              <Col lg={{ span: 5, order: 3 }} className="my-5 text-right">
                <img src="/images/zoom/zoom-verify.webp" alt="Zoom verify" className="img-fluid zoom-verify" />
              </Col>

              <Col lg={{ span: 7, order: 2 }} className="my-lg-5">
                <ul className="h5 my-lg-5">
                  <li className="mb-3">4. You will be redirected to Zoom’s login page (if not logged in).</li>
                  <li className="mb-3">
                    5. Log in to Zoom and click the Authorize button on the Zoom Integration Page.
                  </li>
                  <li className="mb-3">
                    6. Once authorized, your Zoom account will be integrated with Fuse Classroom.
                  </li>
                </ul>
              </Col>
            </Row>
          </article>
        </section>
        {/* Steps to connect Zoom with Fuseclassroom End */}

        {/* Removing zoom from application */}
        <section className="py-5" id="removing-zoom">
          <article className="container">
            <Row>
              <Col md="6">
                <img src="/images/zoom/removing.webp" alt="Removinf zoom" className="img-fluid" />
              </Col>

              <Col md="6" lg={{ span: 5, offset: 1 }}>
                <h2 className="font-weight-bold">Removing the Zoom Integration</h2>
                <div className="h4">Remove Zoom accounts in 3 easy steps</div>
                <ul className="h4 num text-left">
                  <li>Go to Live Class Settings.</li>
                  <li>
                    On the list of integrated Zoom accounts, click on “X” icon on the side of the integrated Zoom
                    account email that needs to be removed.
                  </li>
                  <li>Click on “Yes, Remove” on dialog.</li>
                </ul>
              </Col>
            </Row>
          </article>
        </section>
        {/* Removing zoom from application end */}

        {/* Uninstalling the Fuse Classroom Zoom App */}
        <section className="py-5 bg-80 right" id="uninstall-zoom">
          <article className="container">
            <Row className="">
              <Col md="12">
                <h2 className="font-weight-bold">Uninstalling the Fuse Classroom Zoom App</h2>
              </Col>

              <Col md={{ span: 5 }} lg="4" className="mt-lg-5">
                <ul className="h5">
                  <li className="mb-3">1. Login to your Zoom Account and navigate to the Zoom App Marketplace.</li>
                  <li className="mb-3">2. Click Manage &#x3e; Installed Apps or search for the Fuse Classroom app.</li>
                  <li className="mb-3">3. Click on Fuse Classroom app.</li>
                  <li className="mb-3">4. Click Uninstall.</li>
                </ul>
              </Col>

              <Col md={{ span: 6, offset: 1 }} className="text-right mt-lg-5">
                <img
                  src="/images/zoom/uninstall-zoom.png"
                  alt="Steps to connect Zoom with Fuseclassroom"
                  className="img-fluid uninstall-zoom"
                />
              </Col>
            </Row>
          </article>
        </section>
        {/* Uninstalling the Fuse Classroom Zoom App end */}

        {/* uses */}
        <section className="py-5 bg-green mt-5" id="uses">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10" className="text-center text-white">
                <h2 className="font-weight-bold text-white">Usage</h2>
                <div className="h4 my-4">
                  School Admin can only integrate Zoom Account(s) in Fuse Classroom. Once the School Admin integrates
                  Zoom account(s), instructors of the respective school can create and schedule live classes and access
                  recordings from the Fuse Classroom Instructor Platform. Students can join the scheduled live classes
                  and access recordings from the Fuse Classroom Student Platform.
                </div>

                <div className="h4 my-4">
                  Please follow the instructions below on how live classes can be scheduled, joined and its recordings
                  viewed.
                </div>

                <div className="h4 my-4">
                  For additional help or technical support, please contact our customer support.
                </div>
              </Col>

              <Col lg="10">
                <div className="px-5 py-4 h4 bg-white text-left">
                  <div className="font-weight-bold">
                    Getting started with integrating Zoom account(s) into Fuse Classroom
                  </div>
                  <ul className="arrow mt-3">
                    <li>Fuse Classroom Account with School Admin role</li>
                    <li>
                      Free or Premium Zoom Accounts (Premium accounts are recommended as free accounts have limited
                      features.)
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Uses end */}

        {/* Live Class */}
        <section className="py-5" id="live-classes">
          <article className="container">
            {/* <Row className="">
              <Col lg={{ span: 8, offset: 1 }}>
                <div className="h5 my-4">
                  Once the School Admin integrates the Zoom account, instructors of the respective school can create and
                  schedule live classes and access recordings of the same from the Fuse Classroom instructor platform.
                  Students can join the scheduled live classes and access recordings from the Fuse Classroom student
                  platform.
                </div>
              </Col>
            </Row> */}

            <Row className="py-5 justify-content-center  schedule">
              <Col md={{ span: 6, order: 2 }}>
                <img src="/images/zoom/schedule-live-class.png" alt="schedule-live-class" className="img-fluid" />
              </Col>
              <Col md={{ span: 5, order: 1, offset: 1 }}>
                <div className="ins-title mt-md-4">To schedule Live Class,</div>
                <ul className="num">
                  <li>Log in with the instructor’s credentials in the Fuse Classroom Instructor platform.</li>
                  <li>Go to Live Classes.</li>
                  <li>Click on the CREATE LIVE CLASS button.</li>
                  <li>Fill in the required information and click Done.</li>
                  <li>To start the scheduled class, click on the START NOW button.</li>
                  <li>Click on the Switch to Zoom option to launch Zoom and host the live scheduled class.</li>
                </ul>
              </Col>
            </Row>

            <Row className="py-5 justify-content-center join">
              <Col md={{ span: 6, offset: 1 }}>
                <img src="/images/zoom/join-live-class.png" alt="join-live-class" className="img-fluid" />
              </Col>

              <Col md={{ span: 4, offset: 1 }}>
                <div className="ins-title  mt-md-4">To join scheduled Live Class,</div>
                <ul className="num">
                  <li>Log in with the student’s credentials in the Fuse Classroom Student platform.</li>
                  <li>Go to Live Classes.</li>
                  <li>You will see a list of scheduled classes.</li>
                  <li>
                    Once the instructor starts the class, click on the JOIN NOW button to join the live online class.
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className="py-5 justify-content-center view">
              <Col md={{ span: 6, order: 2 }}>
                <img src="/images/zoom/view-recording.png" alt="view-recording" className="img-fluid" />
              </Col>
              <Col md={{ span: 5, order: 1, offset: 1 }}>
                <div className="ins-title  mt-md-4">To view recordings,</div>
                <ul className="num">
                  <li>Login as an Instructor/Student in the respective platform.</li>
                  <li>Go to Live Classes.</li>
                  <li>Click on Recorded Live Classes on the side panel.</li>
                  <li>Click on VIEW RECORDING to access the recording of a particular class.</li>
                  <li>It will redirect you to Zoom’s page in the new tab.</li>
                  <li>
                    Copy the password provided in the dashboard by clicking copy, and paste it in the Password box shown
                    on Zoom’s page.
                  </li>
                  <li>Click access recording.</li>
                </ul>
              </Col>
            </Row>
          </article>
        </section>
        {/* Live Class end */}
      </Layout>
    )
  }
}

export default Implementation
